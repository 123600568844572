<template>
  <!-- <v-app-bar class="px-3" density="compact"> -->
  <div v-if="loginInfoStore.userName">
    <div class="d-flex mt-10 justify-center">
      <v-card variant="tonal" color="purple">
        <div class="text-h5 px-5 py-3">
          <svg-icon type="mdi" :path="accountIcon"></svg-icon>
          Hi, {{ loginInfoStore.userName }}
        </div>
      </v-card>
    </div>
  </div>
  <!-- </v-app-bar> -->

  <div class="w-100 pt-5 d-flex justify-center text-h5 text-purple-darken-3">
    Zeher Cricket
    <svg-icon
      type="mdi"
      :path="cricketIcon"
      class="text-purple-darken-3"
    ></svg-icon>
  </div>

  <main-page></main-page>
</template>

<script>
import { defineComponent } from "vue";
import { useLoginInfo } from "@/stores/loginInfo";

// Components
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCricket } from "@mdi/js";
import { mdiAccount } from "@mdi/js";

import MainPage from "../components/MainPage.vue";

export default defineComponent({
  name: "HomeView",

  components: {
    MainPage,
    SvgIcon,
  },

  setup() {
    const cricketIcon = mdiCricket;
    const accountIcon = mdiAccount;

    const loginInfoStore = useLoginInfo();

    return {
      loginInfoStore,
      accountIcon,
      cricketIcon,
    };
  },
});
</script>
