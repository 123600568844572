/* eslint-disable prettier/prettier */
import { defineStore } from "pinia";

export const useLoginInfo = defineStore("loginStore", {
    state: () => ({
        userName: "",
    }),
    actions: {
        setUserName(userName){
            this.userName = userName;
        }
    }
});