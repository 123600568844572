<!-- eslint-disable prettier/prettier -->
<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" md="4">
            <v-sheet elevation="10" class="mt-10 pb-5 mx-10 rounded-shaped">
                <div v-if="!isLoggedin && !clickonLoginButton">
                    <div class="w-100" style="height: 200px;">
                        <svg-icon type="mdi" :path="sadIcon" class="w-100 h-100"></svg-icon>
                    </div>
                    <div class="text-h6 text-purple-lighten-1 px-5 d-flex justify-center">
                        login to vote for your team
                    </div>

                    <div class="d-flex justify-center mt-5">
                        <v-btn variant="outlined" elevation="3" @click="onClickLoginBtn">
                            Login
                            <template #prepend>
                                <svg-icon type="mdi" :path="loginIcon"></svg-icon>
                            </template>
                        </v-btn>
                    </div>
                </div>
                <div v-else-if="!isLoggedin && clickonLoginButton">
                    <div class="d-flex flex-column align-center">
                        <v-tabs v-model:model-value="tabIndex">
                            <v-tab>
                                Login
                            </v-tab>
                            <v-tab>
                                Register
                            </v-tab>
                        </v-tabs>

                        <v-window class="w-100" v-model="tabIndex">
                            <v-window-item>
                                <v-text-field class="pt-10 px-1" label="User Name" v-model="loginUserName">
                                </v-text-field>

                                <v-text-field
                                    class="px-1"
                                    label="Password"
                                    type="password"
                                    v-model="loginPassword"
                                >
                                </v-text-field>

                                <div class="mt-3 mb-2 d-flex justify-center">
                                    <v-btn @click="loginUser">
                                        Login
                                    </v-btn>
                                </div>
                            </v-window-item>

                            <v-window-item>
                                <v-text-field class="pt-10 px-1" label="User Name" v-model="registerUserName">
                                </v-text-field>

                                <v-text-field
                                    class="px-1"
                                    label="Password"
                                    type="password"
                                    v-model="registerPassword"
                                >
                                </v-text-field>

                                <div class="mt-3 mb-2 d-flex justify-center">
                                    <v-btn @click="registerNewUser">
                                        Register
                                    </v-btn>
                                </div>
                            </v-window-item>
                        </v-window>
                    </div>
                </div>
                <div v-else>
                    <vote-for-team></vote-for-team>
                </div>
            </v-sheet>
        </v-col>
        <v-col cols="12" md="4">
            <v-sheet elevation="10" class="mt-10 mx-10 rounded-shaped fill-height">
                <ag-grid-vue :columnDefs="colDefs" :rowData="rowData" :auto-size-strategy="autoSizeStrategy"
                    @grid-ready="onGridReady"
                    class="pt-10 px-10 ag-theme-quartz" style="height: 500px; width:100%"></ag-grid-vue>
            </v-sheet>
        </v-col>
    </v-row>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { onMounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useLoginInfo } from '../stores/loginInfo'
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // AG Grid Component

//icons
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiEmoticonSadOutline } from '@mdi/js';
import { mdiLoginVariant } from '@mdi/js';

import VoteForTeam from './VoteForTeam.vue' 

export default {
    components: {
        SvgIcon,
        VoteForTeam,
        AgGridVue,
    },
    setup() {
        const isLoggedin = ref(false);
        const clickonLoginButton = ref(false);

        // notification
        const toast = useToast()

        //store
        const loginInfoStore = useLoginInfo()

        // login window
        const tabIndex = ref(0);
        const loginUserName = ref('');
        const loginPassword = ref('');

        const registerUserName =  ref('');
        const registerPassword = ref('');

        // icons
        const sadIcon = mdiEmoticonSadOutline;
        const loginIcon = mdiLoginVariant;

        const onClickLoginBtn = () => {
            clickonLoginButton.value = true;
        }

        // ag grid
        const rowData = ref([
        ]);

        const colDefs = ref([
            { field: "name" },
            { field: "points"}
        ])

        const autoSizeStrategy = {
            type: 'firGridWidth'
        }

        let gridApi;

        const onGridReady = (pararms) => {
            gridApi = pararms.api;
        }

        onMounted(async () => {
            fetchLeaderboard()

            // check if user is logged in
            if (localStorage.getItem('zeherCricketLoginToken') === null){
                // this is the first time user is loggin in to the app.
                isLoggedin.value = false;
            }else{
                // token is present need to check if the token is valid
                const attemptLogin = await fetch('https://zehercricket.azurewebsites.net/api/Cricket/CheckToken', {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('zeherCricketLoginToken')
                    }
                })

                if (attemptLogin.status === 200){
                    isLoggedin.value = true;
                    loginInfoStore.userName = localStorage.getItem('zeherUserName');
                }else if (attemptLogin.status === 401){
                    localStorage.removeItem('zeherCricketLoginToken')
                    localStorage.removeItem('zeherUserName')
                }
            }
        })
        
        const registerNewUser = async () => {
            const response = await fetch('https://zehercricket.azurewebsites.net//api/RegisterNewUser', {
                method: 'POST',
                body: JSON.stringify({
                    userName: registerUserName.value,
                    password: registerPassword.value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })

            if (response.status === 409){
                // userStore.isLoggedIn = false
                toast('User Already Exists', {
                    type: 'error'
                })
            }else if (response.status === 200){
                // userStore.isLoggedIn = true
                // userStore.userName = serverRes.userName
                // userStore.requireLogin = false

                const serverRes = await response.json()

                isLoggedin.value = true;
                clickonLoginButton.value = false;
                localStorage.setItem('zeherCricketLoginToken', serverRes.token)
                localStorage.setItem('zeherUserName', serverRes.userName)
                loginInfoStore.setUserName(serverRes.userName);

                toast('Registerd Successfully', {
                    type: 'success'
                })
            }
        }

        const loginUser = async () => {
            const res = await fetch('https://zehercricket.azurewebsites.net/api/Login', {
                method: 'POST',
                body: JSON.stringify({
                    userName: loginUserName.value,
                    password: loginPassword.value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8'
                }
            })

            if (res.status === 404){
                toast('Invalid Username or Password', {
                    type: 'error'
                })
            }else if (res.status === 200){
                // logged in successfully
                isLoggedin.value = true;
                clickonLoginButton.value = false;

                const apiRes = await res.json()
                const token = apiRes.token

                localStorage.setItem('zeherCricketLoginToken', token)
                localStorage.setItem('zeherUserName', loginUserName.value)
                loginInfoStore.setUserName(loginUserName.value);

                // userStore.userName = userName
                // userStore.isLoggedIn = true
                // userStore.requireLogin = false

                toast('Logged in Successfully', {
                    type: 'success'
                })
            }
        }

        const fetchLeaderboard = async () => {
            const apiCall = await fetch('https://zehercricket.azurewebsites.net/api/Cricket/Leadeboard', {
            })

            if (apiCall.status == 200){
                const apiRes = await apiCall.json();
                rowData.value = apiRes  
                gridApi.sizeColumnsToFit()
            }
        }

        return {
            isLoggedin,
            sadIcon,
            loginIcon,  
            clickonLoginButton,
            tabIndex,
            rowData,
            colDefs,
            autoSizeStrategy,
            loginUserName,
            loginPassword,
            registerUserName,
            registerPassword,
            onClickLoginBtn,
            registerNewUser,
            loginUser,
            onGridReady,
        }
    }
}
</script>