<!-- eslint-disable prettier/prettier -->
<template>
    <div v-if="alreadyVoted">
        <v-card class="mt-10 mx-10" color="lime-darken-4" variant="tonal">
            <div v-for="voted in showTeamChosen" :key="voted.matchId" class="mx-5 my-5">
                You have voted for {{ voted.teamName }}. you can change your selection till {{ getTimeTillVoting(voted) }}
            </div>
        </v-card>
    </div>
    <div class="text-h6 pt-5 d-flex justify-center">
        Vote for your team today
    </div>
    <div v-for="match in todaysMatchData" :key="match.matchNumber">
        <div class="d-flex justify-center mt-10">
            <v-btn class="mx-10" color="purple-lighten-5" @click="teamChosen = { teamName: match.firstTeam, matchNumber: match.matchNumber }">
                {{ match.firstTeam }}
            </v-btn>

            <v-spacer></v-spacer>

            vs

            <v-spacer></v-spacer>

            <v-btn class="mx-10" color="purple-lighten-5" @click="teamChosen = { teamName: match.secondTeam, matchNumber: match.matchNumber }">
                {{ match.secondTeam }}
            </v-btn>
        </div>
    </div>

    <div v-if="teamChosen.teamName">
        <div class="text-h5 d-flex justify-center mt-10 text-purple-darken-3">
            You chose {{ teamChosen.teamName }}
        </div>

        <div class="d-flex justify-center">
            <v-btn class="mt-3" variant="elevated" color="purple-darken-1" @click="submitYourTeam">
                Submit
            </v-btn>
        </div>
    </div>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ref, watch, onMounted } from 'vue';
import { useToast } from 'vue-toastification';

export default {
    props:{
        dateToFetch: Date,
    },
    setup(props) {
        const todaysMatchData = ref([]);

        const teamChosen = ref({
            teamName: '',
            matchNumber: 0
        });

        const alreadyVoted = ref(false);

        const showTeamChosen = ref([]);

        const toast = useToast()

        const getTodaysMatchDetails = async () => {
            const matchData = await fetch(`https://zehercricket.azurewebsites.net/api/Cricket/GetTodaysMatch?todaysDate=${props.dateToFetch.toDateString()}`)

            if (matchData.status === 200){
                const matchDataRes = await matchData.json();
                todaysMatchData.value = matchDataRes;
            }

            checkIfVotedAlready();
        }

        const getCurrentISTTime = () => {
        // Get current date and time in UTC
            const currentUTCDate = props.dateToFetch;

            // UTC time to IST time conversion
            const ISTOffset = 330 * 60 * 1000; // IST is UTC+5:30 (in milliseconds)
            const ISTTime = new Date(currentUTCDate.getTime() + ISTOffset);

            // Format IST time into a string (e.g., ISO string)
            const ISTTimeString = ISTTime.toISOString(); // Or use any other desired format

            return ISTTimeString;
        }

        const checkIfVotedAlready = async () => {
            const apiCall = await fetch(`https://zehercricket.azurewebsites.net/api/Cricket/CheckIfTeamSelected?UserName=${localStorage.getItem('zeherUserName')}&Date=${props.dateToFetch.toDateString()}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('zeherCricketLoginToken')
                }
            })


            if (apiCall.status === 200){
                const apiRes = await apiCall.json();
                alreadyVoted.value = true;
                showTeamChosen.value = apiRes;
            }
        }

        const getTimeTillVoting = (teamInput) => {
            console.log("team chosen : ", teamInput);
            console.log("todays match data : ", todaysMatchData.value);
            const team = todaysMatchData.value.filter(team => team.matchNumber == teamInput.matchId)

            if (team[0].isSundayFirstMatch){
                return "3:30 PM"
            }
            return "7:30 PM" 
        }

        const submitYourTeam = async () => {
            const todaysDate = new Date().getDate();
            const currentTime = props.dateToFetch;
            const targetTime = props.dateToFetch;
            targetTime.setHours(19);
            targetTime.setMinutes(30);

            const votingForWhichTeam = todaysMatchData.value.filter(team => team.matchNumber == teamChosen.value.matchNumber);

            if (votingForWhichTeam && votingForWhichTeam[0].isSundayFirstMatch){
                targetTime.setHours(15);
                targetTime.setMinutes(30);
            }

            if (todaysDate == props.dateToFetch.getDate() && currentTime >= targetTime){
                toast('Sorry, you can only till 7:30 PM', {
                    type: 'error'
                });
            }else{
                const apiCall = await fetch('https://zehercricket.azurewebsites.net/api/Cricket/EnterUserSelection', {
                    method: 'POST',
                    body: JSON.stringify({
                        id: 0,
                        matchId: teamChosen.value.matchNumber,
                        username: localStorage.getItem('zeherUserName'),
                        teamName: teamChosen.value.teamName,
                        date: getCurrentISTTime()
                    }),
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('zeherCricketLoginToken'),
                        'Content-type': 'application/json; charset=UTF-8'
                    }
                });

                if (apiCall.status === 200){
                    toast('Successfully submitted the vote', {
                        type: 'success'
                    })
                }
                checkIfVotedAlready();
            }
        }

        onMounted(() => {
            getTodaysMatchDetails();
        })

        watch(() => props.dateToFetch, () => {
                teamChosen.value = {
                teamName: '',
                matchNumber: 0
            };
            showTeamChosen.value = [];
            getTodaysMatchDetails();
        })

        return {
            todaysMatchData,
            alreadyVoted,
            showTeamChosen,
            teamChosen,
            submitYourTeam,
            getTimeTillVoting
        }
    }
}
</script>