<!-- eslint-disable prettier/prettier -->
<template>
    <v-row class="d-flex justify-center h-100">
        <v-col cols="12" md="4" class="h-100">
            <div v-if="!authorized">
                <div>
                    401: You dont have access to view this page.
                </div>
                <div>
                    Contact Harsh for access of this page
                </div>
            </div>
            <div v-else class="w-100 h-100">
                <div class="d-flex">
                    <div class="w-25 py-10 px-3">
                        <v-card class="my-10" @click="componentIndex = 0" >
                            <div class="text-h6 px-5 py-5 d-flex justify-center">
                                Update Match Results
                            </div>
                        </v-card>
        
                        <v-card class="text-h6 px-5 py-5 d-flex justify-center" @click="componentIndex = 1">
                            Add Match Info
                        </v-card>
                    </div>
                    <div class="py-10 w-75 px-3">
                        <div class="w-100 my-10 bg-green">
                            <component :is="currentComponent">

                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ref, onMounted, computed } from 'vue';
import UpdateMatch from "../components/admin/UpdateMatch.vue"
import AddMatchInfo from "../components/admin/AddMatchInfo.vue"

export default {
    components: {
        UpdateMatch,
    },
    setup(){
        // check if admin or not
        const authorized = ref(false);

        const tabs = [
            UpdateMatch,
            AddMatchInfo,
        ]

        const componentIndex = ref(0);

        const currentComponent = computed(() => tabs[componentIndex.value])

        onMounted(() => {
            const token = localStorage.getItem("zeherCricketLoginToken");

            if (token == null){
                console.log("not authorized to view the page");
            }else{
                const JWTdata = token.split('.')[1];
                const decodedJWTJsonData = window.atob(JWTdata);
                const decodedJWTData = JSON.parse(decodedJWTJsonData);

                console.log(decodedJWTData.role);
                authorized.value = decodedJWTData.role == "Admin" ? true : false;
            }
        })


        return {
            authorized,
            componentIndex,
            currentComponent,
        }
    }
}
</script>