<!-- eslint-disable prettier/prettier -->
<template>
    <v-tabs class="w-100" v-model="tab">
        <div v-for="date in datesForFiveDays" :key="date">
            <v-tab selected-class="bg-purple-lighten-5">
                {{ date.toLocaleDateString('en', {weekday: 'long'}) }}
            </v-tab>
        </div>
    </v-tabs>

    <match-info-selection :dateToFetch="dateToFetch"></match-info-selection>
</template>
<!-- eslint-disable prettier/prettier -->
<script>
import { ref, computed, onMounted } from 'vue';
import MatchInfoSelection from './MatchInfoSelection.vue'

export default {
    components:{
        MatchInfoSelection,
    },
    setup() {
        const tab = ref(0);

        const datesForFiveDays = [
            new Date,
        ]

        onMounted(() => {
            // populate the dates for next 5 days
            const today = new Date();

            for (let i = 0; i < 5; i++){
                datesForFiveDays.push(new Date(today.setDate(today.getDate() + 1)));
            }
        })

        const dateToFetch = computed(() => {
            return datesForFiveDays[tab.value]
        });

        return {
            tab,
            dateToFetch,
            datesForFiveDays,
        }
    }
}
</script>